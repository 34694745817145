
  
  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  

.envelope-float-animation {
    position: fixed;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    animation: floatEnvelope 3s ease-in-out forwards;
    z-index: 1000;
  }
  
  @keyframes floatEnvelope {
    0% {
      transform: translate(-50%, 0) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, -20vh) scale(1.2);
      opacity: 0.9;
    }
    100% {
      transform: translate(-50%, -60vh) scale(0.8);
      opacity: 0;
    }
  }
  
  .particles-container {
    position: absolute;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .particle {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #fbbf24; /* Gold-like particle color */
    border-radius: 50%;
    animation: particleFloat 3s ease-in-out infinite;
    opacity: 0.8;
  }
  
  .particle:nth-child(1) {
    animation-delay: 0s;
    top: 0;
    left: 0;
  }
  
  .particle:nth-child(2) {
    animation-delay: 0.5s;
    top: 0;
    left: 20px;
  }
  
  .particle:nth-child(3) {
    animation-delay: 1s;
    top: 10px;
    left: -20px;
  }
  
  .particle:nth-child(4) {
    animation-delay: 1.5s;
    top: 10px;
    left: 30px;
  }
  
  @keyframes particleFloat {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 0.8;
    }
    50% {
      transform: translate(20px, -40px) scale(1.5);
      opacity: 0.6;
    }
    100% {
      transform: translate(-20px, -80px) scale(0.8);
      opacity: 0;
    }
  }
  